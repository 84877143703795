<template>
    <div>
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('一卡通收银台')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon  name="card" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      <van-form v-if="isgetorderno" @submit="onSubmit" style="margin-top: 9px;">
         <van-cell-group title="订单信息" inset>
            <van-cell title="单元格" icon="clock-o">
                <template #title>
                    <van-count-down class="custom-time-count" millisecond :time="orderinfo.time" format="HH:mm:ss:SS" />
                    <span class="custom-time">
                        请在以上时间内完成支付，否则会订单会自动取消
                    </span>
                </template>
            </van-cell>
            <van-cell title="单元格" icon="gold-coin">
                <template #title>
                    <span class="custom-title">订单金额</span>
                </template>
                <template #value>
                    <span class="custom-money">{{orderinfo.goodsaccount}}</span>
                </template>
            </van-cell>
            <van-cell title="订单号" icon="cart">
                <template #title>
                    <span class="custom-title">订单号</span>
                </template>
                <template #value>
                    <span class="custom-title">{{orderinfo.goodsorderno}}</span>
                </template>
            </van-cell>
            <van-cell title="商品信息" icon="records" :label="orderinfo.goodsdetail">
                <template #title>
                    <span class="custom-title">订单号</span>
                </template>
                <template #value>
                    <span class="custom-title">{{orderinfo.merchant}}</span>
                </template>
            </van-cell>

        </van-cell-group>
        
        <van-cell-group title="一卡通账号信息" inset >
            <van-field
              v-model="username"
              name="用户名"
              label="用户名"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
            />
     
            <van-password-input
                :value="password"
                :focused="showKeyboard"
                :length="6"
                info="密码为 6 位数字"
                @focus="showKeyboard = true"
                />
            <van-number-keyboard
                v-model="password"
                :show="showKeyboard"
                @blur="showKeyboard = false"
                />
     
        </van-cell-group>


       
        <div v-if="isbind===false" style="margin: 16px;">
          <van-button :loading="isloading" round block type="primary" native-type="submit" loading-text="处理中...">
            去支付
          </van-button>
        </div>
      </van-form>
      <!-- 加载中 -->
      <van-empty v-if="isgetorderno===false" image="search" :description="curstatus" />
    </div>
  </template>
  
  <script>
   import { IsBlank } from '../../utils/tools/index'
  import { Row, Col, Icon, Empty, Cell, CountDown, CellGroup, Form, Field, Button, Popup, Sticky, NavBar, Area, Toast, Calendar, Radio, RadioGroup, NumberKeyboard,PasswordInput, Dialog } from 'vant';
  import { areaList } from '@vant/area-data';
  // import { requestUserBind, requestLoginGetWxLoginUrl, requestLoginGetAliPayLoginUrl } from '../../api/api'
  import {  mapActions } from 'vuex'
  
  export default {
    components: {
      [Row.name]: Row,
      [Empty.name]: Empty,
      [Col.name]: Col,
      [Icon.name]: Icon,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [CountDown.name]: CountDown,
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      [Popup.name]: Popup,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Area.name]: Area,
      [Calendar.name]: Calendar,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [NumberKeyboard.name]: NumberKeyboard,
      [PasswordInput.name]: PasswordInput
    },
    data() {
      return {
        isloading: false,
        loginmode: 3,
        showKeyboard:false,
        active: 0,
        showArea: false,
        showCalendar: false,
        userSex: '',
        userAddress: '',
        showSex: false,
        userArea: '',
        userbirthday: '',
        areaList: areaList,
        username: '',
        paytype: -1,
        password: '',
        isbind: false,
        orderinfo: {time: 2 * 60 * 60 * 1000, goodsorderno: '2024081300019222', goodsdetail: '苹果手机16S Pro Max', merchant: '京东商城', goodsaccount: '8.88' },
        isgetorderno: false,
        curstatus: '正在加载订单中...'
      }
    },
    watch: {
        password (val) {
          if (val.length >=6 ) this.onSubmit()
        },
    },
    computed: {},
    mounted() {
      console.log('mount')
      var _this = this
      const $orderno = this.$route.query.orderno
      console.log($orderno)
      if (IsBlank($orderno)) {
        this.curstatus = '没有获取到订单信息！'
        Dialog.alert({
            title: '错误提醒',
            message: this.curstatus,
        })
        setTimeout(() => {
            _this.$router.replace( '/error?errcode=orderno_empty&errmsg=&' +  + '?t1=' + new Date().getTime());
            // this.$router.replace('/');
        }, 400);
      } else {
        this.GetOrderNo($orderno)
      }
      console.log(this)
    },
    methods: {
    ...mapActions([
        'getLocalLoginInfo',
        'getIsNeedAuthLogin',
        'clearAccessToken',
        'getBaseUserInfo'
      ]),
      async GetOrderNo(orderno) {
        console.log(orderno)
        this.orderinfo.goodsorderno = orderno
        this.isgetorderno = true
      },
      async onSubmit () {

        Dialog.confirm({
          title: '绑定支付',
          message: '您确认用' + this.username + '的一卡通账户进行支付吗？',
        })
          .then(async () => {

                    this.isloading = true
                    const res = { return_code: '0', return_message: '支付成功'}
                    if (res.return_code === '0') {
                        Toast({
                          message: res.return_message ,
                          icon: 'success',
                        })
                        this.isloading = false
                        // 更新accesstoken
                        setTimeout(() => {
                            this.$router.push( '/index' +  '?t1=' + new Date().getTime());
                        }, 500);
                           
                    } else {
                        Dialog.alert({
                            title: '错误提醒',
                            message: '支付' + res.return_message,
                        })
                        setTimeout(() => {
                            this.isloading = false
                        }, 1000);
                        
                    }
        })
      }
    }
  };
  </script>
  
  <style lang="less">
  .user {
    &-poster {
      width: 100%;
      height: 53vw;
      display: block;
    }
  
    &-group {
      margin-bottom: 15px;
    }
  
    &-links {
      padding: 15px 0;
      font-size: 12px;
      text-align: center;
      background-color: #fff;
  
      .van-icon {
        display: block;
        font-size: 24px;
      }
    }
    
  }
  .img-icon {
        height: 20px;
   }
    .custom-title {
        margin-right: 20px;
        vertical-align: middle;
    }
    .custom-money {
        margin-right: 20px;
        vertical-align: middle;
        font-size: 30px;
        color: rgb(245, 8, 8);
    }
    .custom-time {
        margin-right: 20px;
        vertical-align: middle;
        font-size: 13px;
        color: rgb(245, 8, 8);
    }
    .custom-time-count {
        margin-right: 20px;
        vertical-align: middle;
        font-size: 15px;
        color: rgb(62, 15, 235);
    }
  </style>
  